.offer {
  position: fixed;
  z-index: 9999;
  left: 25px;
  bottom: 100px;
  backdrop-filter: blur(14px);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 22;
  background-color: #fff;
  transition: var(--transition);
  cursor: pointer;
}
@media (max-width: 767px) {
  .offer {
    left: 100px;
    bottom: 25px;
  }
}
.offer:hover {
  transform: scale(1.1);
}
.offer svg {
  color: var(--white-color) !important;
  transition: 0.5s all;
}
.offer svg:hover {
  color: #222 !important;
}

.popup-image {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #ffffff10;
  backdrop-filter: blur(14px);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.popup-image img {
  display: block;
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
  border-radius: var(--border-radius);
}
@media(max-width:767px){
  .popup-image img{
    max-width: 100%;
    max-height: 100%;
  }
}
@media(max-width:1199px ) and (min-width:767px){
  .popup-image img{
    max-width: 70%;
    max-height: 50%;
  }
}


