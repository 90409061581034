/* Start footer */
.footer {
  background-color:  #1b84ab;
  background-size: cover;
  padding: 40px 0 0;
}
@media (max-width: 1199px) {
  .footer {
    text-align: center;
  }
}
.footer li {
  display: block;
}
.footer .box h3 {
  color: white;
  font-size: 50px;
  margin: 0 0 20px;
}
.footer .box .social {
  display: flex;
  flex-wrap: wrap;
}
.footer .box .social li {
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .footer .box .social {
    justify-content: center;
  }
}
.footer .box .social li {
  margin-right: 10px;
}

.footer h2 {
  font-size: 22px;
}
.footer .box .social li a {
  background-color: #dd6d0e;
  color: #ededed;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 16px;
  border-radius: 10px;
  transition: var(--transition);
}
.footer .box .social li a i {
  color: white;
}
.footer .box .social .facebook:hover {
  background-color: #1877f2;
}
.footer .box .social .tiktok:hover {
  background-color: #ff0050;
}
.footer .box .social .whatsapp:hover {
  background-color: #25d366;
}
.footer .box .social .linkedin:hover {
  background-color: #0077b5;
}
.footer .box .social .instagram:hover {
  background-color: #833ab4;
}
.footer .box .social .youtube:hover {
  background-color: #ff0000;
}

.footer .box .text {
  margin-top: 20px;
  line-height: 2;
  color: #ededed;
}
.footer .box h2 {
  margin-bottom: 40px;
  color: #fff;
}
.footer .box .links li {
  padding: 15px 0;
  text-align: left;
  transition: var(--transition);
  font-size: 15px;
}
.footer .box .links-ar li {
  text-align: right;
}
.info span a {
  color: #ededed !important;
}
.info span a:hover {
  color: #fff !important;
}

.footer .box .links li:not(:last-child) {
  border-bottom: 1px solid white;
}
.footer .box .links li:hover {
  padding-left: 10px;
}
.footer .box .links-ar li:hover {
  padding-left: 0px;
  padding-right: 10px;
}
.footer .box .links li:hover a {
  color: white;
}
.footer .box .links li a {
  color: #ededed;
  transition: var(--transition);
}
.footer .box .links li a::before {
  content: ">";
  margin-right: 10px;
  color: var(--white-color);
  font-weight: bold;
  font-size: 16px;
}
.footer .box .links-ar li a::before {
  margin-right: 0;
  margin-left: 10px;
}
.footer .time-work {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer .time-work h2 {
  margin-bottom: 40px;
  color: #fff;
}
.footer .time-work .line {
  margin-bottom: 15px;
}
.footer .time-work .line p {
  margin-bottom: 0;
}
.footer .line {
  display: flex;
  align-items: center;
  color: #ededed;
  margin-bottom: 30px;
}
.footer .line span {
  margin: 0px 10px;
  font-size: 12px;
  width: 75px;
}
.footer .line p {
  font-size: 12px;
}

@media (max-width: 1199px) {
  .footer .box .links li {
    text-align: left;
  }
  .footer .box .links-ar li {
    text-align: right;
  }
  .footer .line .info {
    margin-right: 0;
    margin-top: 15px;
  }
  .footer .time-work{
    align-items: flex-start;
  }
}
.footer .line .info {
  line-height: 1.8;
  flex: 1;
  margin-left: 20px;
}
.footer .line .info span {
  display: block;
  font-size: 16px;
  width: 100%;
}

.footer .copyright {
  padding: 25px 0;
  margin: 0;
  text-align: center;
  font-size: 12px;
  color: white;
  border-top: 1px solid #444;
}
.footer .copyright span a {
  color: #fff;
  font-weight: bold;
}
@media (max-width: 1199px) {
  .footer .copyright {
    font-size: 8px !important;
  }
}
/* End footer */
