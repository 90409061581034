.counter {
  color: #eb3b5a;
  font-family: "Muli", sans-serif;
  width: 200px;
  height: 200px;
  text-align: center;
  border-radius: 100%;
  padding: 77px 32px 40px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
.counter:before,
.counter:after {
  content: "";
  background: #fff;
  width: 80%;
  height: 80%;
  border-radius: 100%;
  box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.3);
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
}
.counter:after {
  background: linear-gradient(45deg, #b81242 49%, #d74a75 50%);
  width: 100%;
  height: 100%;
  box-shadow: none;
  transform: translate(0);
  top: 0;
  left: 0;
  z-index: -2;
  clip-path: polygon(50% 50%, 50% 0, 100% 0, 100% 100%, 0 100%, 0 50%);
}
.counter .counter-icon {
  color: #fff;
  background: linear-gradient(45deg, #b81242 49%, #d74a75 50%);
  font-size: 33px;
  line-height: 70px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: all 0.3s;
}
.counter .counter-icon i.fa {
  transform: rotateX(0deg);
  transition: all 0.3s ease 0s;
}
.counter:hover .counter-icon i.fa {
  transform: rotateX(360deg);
}
.counter h3 {
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 3px;
}
.counter .counter-value {
  font-size: 30px;
  font-weight: 700;
}
.counter.orange {
  color: #f38631;
}
.counter.orange:after,
.counter.orange .counter-icon {
  background: linear-gradient(45deg, #f38631 49%, #f8a059 50%);
}
.counter.green {
  color: #88ba1b;
}
.counter.green:after,
.counter.green .counter-icon {
  background: linear-gradient(45deg, #88ba1b 49%, #acd352 50%);
}
.counter.blue {
  color: #5db3e4;
}
.counter.blue:after,
.counter.blue .counter-icon {
  background: linear-gradient(45deg, #5db3e4 49%, #7ebee1 50%);
}
@media screen and (max-width: 990px) {
  .counter {
    margin-bottom: 40px;
  }
}
