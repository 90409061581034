.section-4 .slider {
  height: 175px;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  border-radius: 0 !important;
  box-shadow:  0 0 20px 1px #eee;
}

.section-4 .slider .slide-track {
  animation: scroll 40s linear infinite;
}
.section-4 .slider .slide {
  width: fit-content;
  padding: 20px;
  width: 300px;
  margin: 0px 20px;
  color: var(--white-color);
  display: flex;
  align-items: center;
  cursor: pointer;

  backdrop-filter: blur(14px);
  padding: 10px;
}
@media (max-width: 767px) {
  .slider {
    height: 500px !important;
  }
}
.section-4 .slider .slide img {
  width: 250px;
  margin: 20px auto;
  padding: 5px;
  border-radius: var(--border-radius);
  object-fit: contain;
}









