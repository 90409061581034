.contact-us {
  margin-top: 160px;
  background-size: cover;
  padding: 0px 0px 50px;
  background-size: cover;
  background-attachment: fixed;
}
.contact-us h2 {
  position: relative;
  width: fit-content;
  margin: auto;
  margin-bottom: 50px;
}
.contact-us h2::before {
  position: absolute;
  content: "";
  width: 50%;
  height: 2px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -15px;

}
.contact-us-box,
.contact-us form {
  background-color: white;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--border-radius);
}
.contact-us form label {
  display: flex;
  flex-direction: column;
  margin: 15px auto;
  text-align: left;
  width: 75%;
}

.contact-us-ar label {
  text-align: right !important;
}
.contact-us form textarea {
  resize: none;
}

.submitInput{
  background-color: #dd6d0e;
  color: white;
  width: 150px;
  height: 35px;
  border: none;
  border-radius: 10px;

}

.contact-us form textarea,
.contact-us form input {
  margin-top: 5px;
  background-color: var(--blur-color-white);
  border:solid 1px;
  border-radius: var(--border-radius);
  padding: 10px 30px 10px 10px;
  width: 100% !important;
  transition: var(--transition);
}
.contact-us form textarea::placeholder,
.contact-us form input::placeholder {
  opacity: 1;
  transition: var(--transition);
  color: #ffffff90;
}


.contact-us form textarea:focus::placeholder,
.contact-us form input:focus::placeholder {
  opacity: 0;
}
.contact-us input[type="submit"] {
  padding: 10px;
  text-align: center;
  width: 30% !important;
  transition: var(--transition);
  font-weight: bold;
  margin: 10px auto;
}
.contact-us input[type="submit"]:hover {
  color: var(--secondary-color);

}
.contact-us-box-2 .contact-box{
display: flex;
align-items: center;
height: 100px;
}
.contact-us-box-2 .contact-box svg{
font-size: 30px;
width: 40px !important;
height: 40px !important;
margin: 10px;
color: var(--secondary-color);
border-radius: var(--border-radius-half);
padding: 5px;
}
.contact-us-box-2 .contact-box p{
  width: 80%;
  margin: 10px !important;
}
@media (max-width: 1199px) {
  .contact-us {
    margin-top: 100px;
    padding: 0px 0px 50px !important;
  }
  .contact-us-ar{
    padding:  0px 0px 50px !important;
  }
  .contact-us-box {
    margin: 10px auto;
  }
  .contact-us-box,
.contact-us form{
  padding: 20px 10px;
}
.contact-us form textarea,
.contact-us form input{
  font-size: 14px;
}
.contact-us-box-2 .contact-box svg{
  width: 30px !important;
  height: 30px !important;
}
.contact-us-box-2 .contact-box p{
  font-size: 14px;
  max-width: 90%;
}
.contact-us-box{
  font-size: 14px;
}
.contact-us-box img{
  max-width:40%;  
}
}

.contact-us-box img {
  width: 125px;
  height: 125px;
}
.contact-us-box p {

  line-height: var(--line-height);
  margin: 10px auto;
}
