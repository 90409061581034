.testimonials {
  position: relative;
  padding: 100px 0px 100px;
  background-color: #1b84ab;

}
body{
  background-image: url('../../assets/images/portfolioBackground.webp');
  background-attachment: fixed;
  background-size: cover;
}
.testimonials::before{
  content: '';
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

h3{

  color: white;
};


@media(max-width:767px){
  .testimonials h3{
    font-size: 16px;
  }
}