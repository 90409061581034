.header-link{
    position: relative;
    display: flex;
    justify-content: left;
    align-items: center;
    height: 7vh;
    margin-bottom: 25px;
    margin-top: 0 !important;
    background-size: cover;
  }
  .header-link::before{
    position: absolute;
    content:'';
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background-color: #ccd2d6;
    backdrop-filter: blur(2px);
  }
  .header-link h1{
    position: relative;
    display: flex;
    align-items: center;
    color: black;
    font-size: 22px;
    margin-bottom: 0;
  }
  .header-link a{
  color: black;
  font-size: 22px;
  margin:0px 10px;
  transition: var(--transition);
  }
  .header-link a:hover{
    color: black;
    transform: scale(1.1);
  }
  
  @media(max-width:767px){
    .header-link h1,
    .header-link a{
      font-size: 14px;
    }
  }