.section-2 {
  background-image: url('/src/assets/images/consultant.webp');
  height: 25rem;
  margin-top: 3px;
  background-size: cover;
  position: relative;
  background-attachment: fixed;
  color: white;
}

.section2-des{
  background-color: #ccd2d678;
  color: black;
  position: absolute;
  bottom: 0px;
  margin-bottom: 9rem;
}

@media (max-width: 1199px) {

  .section-2 {
    padding: 20px 5px;
  }

  
}

