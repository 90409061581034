/*start map section */
.map {
  position: relative;
  background-size: cover;

  
}
.map h2 {
  position: relative;
  margin: 50px auto 20px;
}
.map .main-title {
  position: relative;
  color: var(--white-color);
  font-size: 1.5rem;
  font-weight: bold;
  width: max-content;
  background-color: var(--blur-color-black);
  padding: 10px 20px;
  border-radius: var(--border-radius);
  backdrop-filter: blur(14px);
  margin-bottom: 50px;
  box-shadow: 2px 5px 3px var(--blur-color-black);
}
.title-map {
  text-align: center;
  line-height: 1.7;
  font-size: 2.5rem;
  color: white;
  padding: 30px;
  background-color: #1b84ab;
}


iframe {
  width: 100%;
  height: 300px;
  
}

@media (max-width: 1199px) {
  .title-map {
    padding: 10px;
    font-size: 16px;
  }
}
/*end map section */
