.company-section {
  position: relative;
  margin-top: 140px;
  padding: 50px 0px;
  background-size: cover;
  background-image: url("../../assets/images/portfolioBackground.webp");
  background-attachment: fixed;
}

.company-section h2 {
  margin: 30px auto 50px;
  position: relative;
  color: var(--white-color);
  background-color: var(--blur-color-black);
  width: fit-content;
  padding: 10px;
  border-radius: var(--border-radius);
}
.company-logo img {
  position: relative;
  height: 300px;
  max-width: 100%;
  margin: 10px auto;
  backdrop-filter: blur(14px);
  border-radius: var(--border-radius);
  object-fit: contain;
}
.image-gallery-svg {
  width: 30px !important;
  color: var(--white-color) !important;
}
.image-gallery-svg:hover {
  color: var(--black-color) !important;
}

.image-gallery-thumbnail {
  background-color: var(--blur-color-white) !important;
  padding: 10px !important;
  border-radius: var(--border-radius);
  margin: 10px 10px !important;
}
.image-gallery-thumbnail.active {
  border: none !important;
  box-shadow: 0 0 3px var(--white-color);
}
@media (max-width: 1199px) {
  .company-section {
    margin-top: 100px;
  }

  .company-logo img {
    height: 250px;
    margin-bottom: 30px;
  }
}
