.portfolio-card {
  background-image: linear-gradient(45deg, #eee, #ffffff20) !important;
  backdrop-filter: blur(14px);
  position: relative;
  color: var(--secondary-color);
  box-shadow: 1px 3px 3px var(--secondary-color);
  margin: 10px auto;
  transition: var(--transition);
  border-radius: 50px 5px !important;
  overflow: hidden;
  cursor: pointer;
}
.portfolio-card::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #63636320;
  left: -100%;
  transition: 0.6s;
}
.portfolio-card:hover::before {
  display: block;
  left: 0;
}
.portfolio-card:hover {
  transform: scale(0.9);
}
.portfolio-card:hover button {
  transform: scale(1.3);
}

.portfolio-card-image {
  width: 100%;
  height: auto;
  width: 286px;
  height: 277px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.portfolio-card img {
  max-width: 90%;
  margin: 10px auto;
  object-fit: contain;
}
.portfolio-card button {
  background-color: #dd6d0e!important;
  outline: none;
  border: none;
  transition: var(--transition) ease-in-out !important ;
  margin: 20px auto;
}
.portfolio-card button:hover {
  box-shadow: 0 0 2px var(--secondary-color);
}
