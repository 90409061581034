@keyframes scroll {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.slider {
  height: 350px;
  margin: 50px auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  cursor: pointer;
}
/* .slider:hover .slide-track {
  animation: scroll 100s linear infinite;
} */
.slider .slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
}
.slider .slide {
  height: 100px;
  width: 400px;
  padding: 20px;
  color: var(--white-color);
  transition: var(--transition);
}
.slider .slide:hover{
  transform: scale(1.2);
}
.slider .slide img {
  width:100px ;
  height: 100px;
  margin: 20px auto;
  margin-bottom: -30px;
}
.slider .slide h5 {
  background-color: var(--blur-color-white);
  padding: 40px 10px 25px;
  border-radius: var(--border-radius);

}
.slider .slide .reviews-star {
  padding: 5px;
  border-radius: var(--border-radius-20);
  width: fit-content;
  margin: -20px auto 0px;
}
@media (max-width: 767px) {
  .slider {
    height: 350px !important;
  }
  .slider h5 {
    font-size: 12px;
    width: 200px !important;
    padding-left: 0 !important ;
    padding-right: 0 !important ;
  }
}
