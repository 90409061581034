.logos-page .logos-image,
.business-image img {
  position:static ;
  transition: var(--transition);
  object-fit: contain;
  max-width: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.logos-page .logos-image:hover,
.business-image img:hover {

  transform: scale(1.1);
  cursor: pointer;
  backdrop-filter: blur(14px);
}

@media (max-width: 767px) {
  .logos-page .logos-image img,
  .business-image img:hover {
    transform: scale(1) !important;
  }
}
