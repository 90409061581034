.whats-app {
  position: fixed;
  z-index: 9999;
  left: 25px;
  bottom: 25px;
  background-color: #1b84ab;
  backdrop-filter: blur(14px);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.whats-app svg {
  color: var(--white-color) !important;
  transition: 0.5s all;
}
.whats-app svg:hover {
  color: #222 !important;
}
.whats-app::before {
  content: "";
  position: absolute;
  width: 35px;
  height: 35px;
  background-color: #ccd2d6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  transition: all 1s;
  animation: highlight 2s linear infinite;
}
@keyframes highlight {
  100% {
    transform: scale(2.5);
    opacity: 0;
    box-shadow: 0 0 10px var(--main-color);
  }
}
