.our-customers {
  position: relative;
  padding: 30px 0px;
  background-size: cover;
}

.rotate-title {
  position: relative;
  color: var(--white-color);
  font-size: 1.5rem;
  font-weight: bold;
  width: fit-content;
  margin: auto;
  background-color: var(--blur-color-black);
  padding: 10px 20px;
  border-radius: var(--border-radius);
  backdrop-filter: blur(14px);
  margin-bottom: 50px;
  box-shadow: 2px 5px 3px var(--blur-color-black);
}

.rotate-slide-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
  overflow-x: hidden;
}
.rotate-slide {
  position: relative;
  width: 150px;
  height: 150px;
  transform-style: preserve-3d;
  --webkit-transform-style:preserve-3d;
  --moz-transform-style:preserve-3d;
  animation: animate 25s linear infinite;
}

.rotate-slide span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: center;
  transform-style: preserve-3d;
  transform: rotateY(calc(var(--i) * 65deg)) translateZ(350px);
  --webkit-transform: rotateY(calc(var(--i) * 65deg)) translateZ(350px);
  --moz-transform: rotateY(calc(var(--i) *65deg)) translateZ(350px);
  /* background-color: var(--blur-color-white); */
  background-color: var(--white-color);
  /* backdrop-filter: blur(14px); */
  border-radius: var(--border-radius);
}

.rotate-slide span img {
  position: absolute;
  top: 50%;
  left: 0;
  width: fit-content;
  max-width: 100%;
  transform: translateY(-50%);
  object-fit: cover;
}
@media(max-width:767px){
  .rotate-slide{
    width: 125px;
    height: 125px;
  }
}
@keyframes animate {
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }
  100% {
    transform: perspective(1000px) rotateY(360deg);
  }
}
