.about-us {
  position: relative;
  margin-top: 160px;
  background-size: cover;
  background-attachment: fixed;
  background-image: url('/src/assets/images/aboutus-cover.jpg');
}

.about-us-section-2,
.about-us-section-1 {
  background-size: cover;


}

.link-website{
  background-color: #dd6d0e;
  height: 35px;
  border-radius: 7px;
  width: max-content;
  padding: 20px;
  padding-top: 12px;
  font-size: 1rem;
  color: white;
}

.about-us-description {

  margin-bottom: 60px;
}

.about-us-content{
  background-color: var(--blur-color-black);
  color: white;
  padding: 20px;
  border-radius: 10px;
  padding: 50px;
  text-align: start;
}

.about-us-content h2{
  background-color: #dd6d0e;
  height: 35px;
  border-radius: 7px;
  width: max-content;
  padding: 20px;
  padding-top: 3px;
  font-size: 1.5rem;
  color: white;
}

.imgs{
  max-width: 100%;
  position: relative;
}

.about-us-image img {
  position: relative;
  max-width: 50%;
  border-radius: var(--border-radius);
}
@media (max-width: 1199px) {
  .about-us{
    margin-top: 100px;
  }
  .about-us-section-2,
  .about-us-section-1 {
    padding: 20px 5px;
  }
  .about-us-image img {
    display: none;
  }
  .about-us-title{
    font-size: 16px;
  }
  .about-us-description{
    font-size: 14px;
  }
}
