.section-3 {
  background-size: cover;
  padding: 50px;
  position: relative;
  margin-top: -1rem;
  background-color: aliceblue;
}

.icon{
  width: 5rem;
  height: 5rem;
  margin-top: 2rem;
}

.divIcon{
  margin: auto;
  margin-bottom: 1rem;
  width: 10rem;
  height: 10rem;
  border: solid 1.5px;
  border-radius: 10rem;
  border-color: #dd6d0e;
  padding: 10px;

}

.titleSection{
  color: black;
  margin-bottom: 20px;
}
.section-3-container {

  padding: 15px;
  
  line-height: 1.8 !important;
}

.box-section-3 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  min-height: 275px;
  margin: 5px;
}

.titleUnderLine{
  
  height: 4px;
  width: 150px;
  margin: auto;
  margin-top: -1rem;
  background-color: #6ac9ec;
  border-radius: 10px;
  margin-bottom: 2.5rem;
}


.box-section-3 h6 {
  font-size: 18px;
}
.box-section-3 p {
  font-size: 16px;
}
@media (max-width: 1199px) {
  .box-section-3 h5 {
    font-size: 14px;
  }
  .box-section-3 p {
    font-size: 12px;
  }
  .section-3 {
    padding: 20px 5px;
  }

  .box-content-section-3 h6{
   font-size: 14px;
  }
  .box-content-section-3 p{
  font-size: 12px;
  }
}
video {
  max-width: 100%;
  max-height: 100%;
  border-radius: var(--border-radius);
  margin: 10px auto;
}
