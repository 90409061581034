/* Start pricing */
.pricing {
  padding-bottom: 50px;
  position: relative;
}

.pricing .title {
  color: var(--white-color) !important;
}
.pricing .box {
  position: relative;
  box-shadow: 0 12px 20px 0 rgb(0 0 0 / 13%), 0 2px 4px 0 rgb(0 0 0 / 12%);
  transition: var(--transition);
  background-color: var(--blur-color-black);
  backdrop-filter: blur(14px);
  text-align: center;
  z-index: 1;
  border-radius: var(--border-radius);
  margin: 10px auto;
  height: 979.5px;
}

.pricing .box::before {
  content: "";
  position: absolute;
  width: 0;
  height: 50%;
  right: 0;
  top: 0;
  background-color: #535353;
  transition: var(--transition);
  z-index: -1;
}
.pricing .box::after {
  content: "";
  position: absolute;
  width: 0;
  height: 50%;
  left: 0;
  bottom: 0;
  background-color: #535353;
  z-index: -1;
  transition: var(--transition);
}
.pricing .box:hover::before,
.pricing .box:hover::after {
  width: 100%;
}
@media (min-width: 1199px) {
  .pricing .box.popular {
    top: -20px;
  }
}
.pricing .box.popular .label {
  position: absolute;
  writing-mode: vertical-rl;
  background-color: var(--main-color);
  color: var(--white-color);
  font-weight: bold;
  padding: 10px 10px 35px 10px;
  font-size: 18px;
  right: 20px;
  top: -100%;
  width: 40px;
  transition: 0.7s;
}
.pricing .box.popular:hover .label {
  top: 0;
}
.pricing .box.popular .label::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  bottom: 0;
  border: 20px solid;
  border-color: transparent transparent    #535353 transparent;
}
.pricing .box {
  overflow: hidden;
}
.pricing .box .title {
  font-weight: bold;
  font-size: 25px;
  margin: 30px auto;
  letter-spacing: -1px;
  color: var(--main-color-alt);
}
.pricing .box img {
  width: 80px;
  margin-bottom: 30px;
}
.pricing .box .price {
  margin-bottom: 20px;
}
.pricing .box .price .amount {
  display: block;
  font-size: 60px;
  color: var(--secondary-color);
  font-weight: bold;
  margin-bottom: 5px;
}
.pricing .box .price .time {
  color: var(--white-color);
}
.pricing .box ul {
  text-align: left;
  padding: 0 !important;
  height: 585px;
}
.pricing-ar .box ul {
  text-align: right !important;
  direction: rtl;
}
.pricing .box ul li {
  padding: 20px;
  border-top: 1px solid #eee;
  color: var(--white-color);
}
.pricing .box ul li::before {
  content: ">";
  margin-right: 10px;
  font-weight: 900;
  color: var(--white-color);
  font-weight: bold;
}
.pricing-ar .box ul li::before {
  content: ">";
  margin-left: 10px;
  font-weight: 900;
  color: var(--secondary-color);
  font-weight: bold;
}
.pricing .box a {
  display: block;
  width: fit-content;
  border: 2px solid var(--white-color);
  color: var(--white-color);
  margin: 30px auto 40px;
  padding: 15px 20px;
  border-radius: 6px;
  font-weight: bold;
  transition: var(--transition);
}
.pricing .box a:hover {
  color: white;
  background-color: var(--main-color);
  transform: scale(0.96);
  border-color: var(--main-color);
}
@media(max-width:1199px){
  .pricing .box .price .amount{
    font-size: 20px;
  }
  .pricing .box ul li{
    font-size: 12px;
  }
  .pricing .box a{
    padding: 10px 15px;
    font-size: 12px;
  }
  .pricing .box .title{
    font-size: 26px;
    margin: 0px;
    margin-top: 20px;
  }
  .pricing .box{
    height: fit-content;
  }
}
/* End pricing */
