.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: var(--white-color);
  z-index: 20;
}
.navBar {
  display: flex;
  height: 100px;
  justify-content: space-between !important;
  align-items: center !important;
}

.navBar .lang {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.navBar .lang img {
  width: 25px;
  height: 25px;
  margin: 0 5px;
  border-radius: var(--border-radius-half);
  cursor: pointer;
}

.nav-burger {
  position: relative;
  width: 20px;
  height: 14px;
  cursor: pointer;
  margin: 0 15px;
  transition: var(--transition);
  display: none;
}
.nav-burger span {
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: var(--secondary-color);
  left: 0;
  transition: all var(--transition) ease;
}
.nav-burger span:first-child {
  top: 0;
}
.nav-burger span:nth-child(2) {
  top: 7px;
}
.nav-burger span:nth-child(3) {
  top: 13px;
}
.nav-burger.active span:nth-child(2) {
  display: none;
}
.nav-burger.active span:nth-child(1),
.nav-burger.active span:nth-child(3) {
  top: 6px;
}
.nav-burger.active span:first-child {
  transform: rotate(45deg);
}
.nav-burger.active span:last-child {
  transform: rotate(-45deg);
}
.nav-burger.active {
  transform: rotate(2turn);
}

.navLinks {
  display: flex;

  align-items: center;
}
.navLinks .navLink {
  position: relative;
  text-align: center;
  margin: 0 15px;
  width: fit-content;
  display: block;
  padding: 10px 2px;
  border-radius: 50px;
  color: var(--secondary-color);
  transition: var(--transition);
}
.navLinks .navLink:not(:last-child):before {
  position: absolute;
  content: "";
  height: 2px;
  left: 50%;
  width: 0;
  transform: translateX(-50%);
  background-color: #1b84ab;
  bottom: 0;
  transition: var(--transition);
}
.navLinks .navLink:hover:before {
  width: 50%;
}
.navLinks .navLink a {
  transition: var(--transition);
  font-weight: 400;
}
.navLinks .navLink:hover a {
  color: #1b84ab;
  cursor: pointer;
}
.navLinks li.active {
  box-shadow: 0 0 10px var(--main-color);
  cursor: pointer;
}
.navLinks .navLink:not(:last-child) {
  margin-right: 10px;
}
.navLinks .navLink a {
  text-decoration: none;
  color: var(--secondary-color);
  font-size: 16px;
}

@media (max-width: 1380px) {
  .nav .container {
    width: 100%;
  }
  .navLinks {
    flex-direction: column;
    position: absolute;
    display: none;
    z-index: 9999;
    right: 0;
    top: 100%;
    width: 100%;
    height: 100vh;
    background-color: var(--blur-color-black);
    backdrop-filter: blur(14px);
    padding: 15px;
  }
  .navLinks .navLink a {
    color: var(--white-color);
  }
  .navLinks .navLink {
    margin-top: 10px;
    border-radius: 10px;
  }
  .navLinks.active {
    display: block;
  }

  .nav-burger {
    display: block;
  }
}
.flip-container {
  perspective: 1000;
}

.flip-container .flipper {
  animation: flipper 5s infinite;
}
@keyframes flipper {
  0% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0);
  }
}

/*speed of transition*/
.flipper {
  transition: 1s;
  transform-style: preserve-3d;
  position: relative;
}

.flip-container,
.front,
.back {
  width: 150px;
  height: 75px;
  border-radius: 50%;
}

.front,
.back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.front {
  z-index: 2;
}

.back {
  transform: rotateY(180deg);
}
.language img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
}
.flipper img {
  max-width: 100%;
}
