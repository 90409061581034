.frame-title{
    position: relative;
    width: fit-content;
    padding: 10px 25px;
    border-radius:   15px ;
    margin: 2px auto !important;

}

.title{
    color: black;
}

.titleUnderLine{
    height: 4px;
    width: 150px;
    margin: auto;
    margin-top: -2px;
    background-color: #6ac9ec;
    border-radius: 10px;
    margin-bottom: 11px;
}

.frame-title h3{
    margin-bottom: 0px !important;
}

@media(max-width:767px){
    .frame-title{
        margin-bottom: 40px !important;
    }
    .frame-title h3{
        font-size: 18px;
    }
}