.privacy-section {
  position: relative;
  margin-top: 100px;
  background-image: linear-gradient(to bottom , #222 ,#555  );
  padding: 50px 10px;
}

.privacy-title {
  position: relative;
  margin: 30px auto;
  background-color: var(--blur-color-black);
  color: var(--white-color);
  width: fit-content;
  padding: 15px;
  border-radius: var(--border-radius);
}
.privacy-des {
  position: relative;
  line-height: var(--line-height);
  background-color: var(--blur-color-white);
  backdrop-filter: blur(14px);
  box-shadow: 0 0 3px var(--secondary-color);
  border-radius: var(--border-radius);
  padding: 10px;
  color: var(--white-color);
}
@media (max-width: 767px) {
  .privacy-des {
    font-size: 14px;
  }
}
