.logos-page{
    margin-top: 160px;
    padding-bottom: 100px;
    padding-top: 5px;
}
.logos-page .frame-title{
    margin: 50px auto !important;
}
.logos-page  .logos-image img{
    max-width: 100%;
    height: 100%;
    object-fit:contain  ;
}
.logos-page .logos-image{
display: flex;
justify-content: center;
align-items: center;
margin: 20px auto;
height: 300px !important;
width: 300px !important;
overflow: hidden;
box-shadow: 0 0 4px var(--secondary-color);
border-radius: var(--border-radius)
}

@media(max-width:767px){
    .logos-page{
        margin-top: 100px;
    }
}

