.main-section {
  margin-top: 160px;
}
.main-section .carousel {
  position: relative;
  padding: 0 !important;
  margin: 0 !important;
}
.main-section .carousel img {
  max-width: 100% !important;
  height: 85vh;
  
}
/* .main-section video{
  object-fit: contain !important;
} */
.main-section .carousel video {
  width: 100vw !important;
  height: 82.5vh;
  border-radius: 0 !important;
  object-fit: cover;
  
}
.carousel-control-next, .carousel-control-prev{
  display: none !important;
}
@media (max-width: 767px) {
  .main-section {
    margin-top: 100px;
  }
  .main-section .carousel{
    height: fit-content !important;
  }
  /* .main-section .carousel:last-child{
    display: none;
  } */
  .main-section .carousel img {
    object-fit: contain;
    height: fit-content !important;
  }
 
  .main-section .carousel video {
 
    margin: 0 !important;
    border-radius: 0 !important;
    object-fit: contain;
    height: fit-content !important;
    }
  .main-form {
    display: none;
  }
  .carousel-control-next,
  .carousel-control-prev {
    height: 50vh !important;
  }
}
.main-section .image-carousel {
  position: relative;
}

.main-section .carousel-buttons {
  margin-bottom: 150px;
}
.carousel-buttons a {
  background-color: #00000070;
  color: var(--white-color);
  padding: 15px;
  margin: 5px;
  border-radius: var(--border-radius);
  font-size: 20px;
  transition: var(--transition);
}
.carousel-buttons a:hover {
  background-color: var(--black-color);
  color: var(--white-color);
  transform: scale(1.1);
}
.carousel h3 {
  padding: 10px;
  max-height: 100%;
  font-size: 22px;
  background-color: #00000099;
  border-radius: var(--border-radius);
  width: fit-content;
  margin-bottom: 20px !important;
  margin: auto;
}
.carousel p {
  padding: 10px;
  max-height: 100%;
  font-size: 22px;
  background-color: #00000099;
  border-radius: var(--border-radius);
  width: 60%;
  margin: auto;
  margin-bottom: 150px;
}

.main-form {
  color: var(--white-color);
  background-color: var(--blur-color-black);
  backdrop-filter: blur(14px);
  border-radius: var(--border-radius);
  padding: 20px;
  text-align: center;
  width: 40%;
  position: relative;
  margin: auto;
  transform: translate(-350px, -80px);
}
.main-section-ar .main-form {
  margin-right: auto;
}
.main-form p {
  background-color: transparent;
  margin-bottom: 20px;
}
.main-form svg {
  font-size: 60px;
  box-shadow: 0 0 2px var(--white-color);
  padding: 10px;
  border-radius: var(--border-radius-half);
  margin: 20px auto;
  width: 100px;
  height: 100px;
  color: var(--white-color);
}
.main-section-part-1 button,
.main-form input {
  display: block;
  border-radius: var(--border-radius-20);
  outline: none;
  border: none;
  box-shadow: none;
  padding: 10px 15px;
  width: 100%;
  background-color: var(--blur-color-white);
  caret-color: var(--white-color);
  color: var(--white-color);
}
.main-form input::placeholder {
  color: #eeeeee90;
  opacity: 1;
  transition: var(--transition);
  text-align: center;
}
.main-form input:focus::placeholder {
  opacity: 0;
}
.main-section-part-1 button,
.main-form input[type="submit"] {
  width: 40%;
  margin: 20px auto;
  color: var(--white-color) !important;
  transition: var(--transition);
  border: none;
}
.main-section-part-1 button:hover,
.main-form input[type="submit"]:hover {
  background-color: var(--blur-color-black);
}

@media (max-width: 767px) {
  .carousel h3 {
    font-size: 13px;
  }
  .carousel p {
    font-size: 12px;
    margin-bottom: 20px;
    width: 100%;
  }
  .carousel-buttons {
    margin-bottom: 0 !important;
  }
  .carousel {
    height: 50vh;
  }
  .carousel img {
    height: 50vh;
    object-fit: cover;
  }
  .carousel-buttons a {
    font-size: 14px;
    padding: 10px;
  }
}
@media (max-width: 1199px) {
  .main-form {
    width: 100%;
    transform: translateY(50px);
    height: 300px;
    padding-top: 5px;
    padding-bottom: 5px !important;
  }
  .main-form svg {
    width: 60px;
    height: 60px;
    margin: 5px auto 15px;
  }
  .head-text h5 {
    font-size: 16px;
  }
  .head-text h3 {
    font-size: 14px;
  }
  .head-text p {
    font-size: 12px;
  }
  .main-form h5 {
    font-size: 14px;
  }
  .main-form p {
    font-size: 12px;
  }
  .main-section-part-1 button,
  .main-form input {
    margin: 10px auto;
  }
  .main-section-part-1 button,
  .main-form input {
    padding: 5px 7.5px;
  }
  .main-form input::placeholder {
    font-size: 12px;
  }
  .main-form input[type="submit"] {
    font-size: 14px;
  }
}
