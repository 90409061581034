.top-nav{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    background-color: #1b84ab;
    height: 40px;
    padding: 30px 0px !important;
}
.top-nav svg{
    color: var(--white-color);
    font-size:20px !important;
    margin: 10px;
}
.top-nav .box{
    display: flex;
    align-items: center !important;
    margin: 0px 20px;
}
.top-nav .box p{
    font-size: 14px;
    color: var(--white-color);
    margin: 0;
}
.top-nav .box .social{
    display: flex;
    align-items: center;

}
.top-nav .box .social svg{
    color: var(--white-color) !important;
    font-size:18px !important;
}

@media(max-width:1199px){
    .top-nav{
        display: none;
    }
}