.takfil-digital {
  position: relative;
  margin-top: 160px;
  background-size: cover;
  background-position: right;
  min-height: 100vh;
  padding: 0px 0px 100px;
}

@media (max-width: 1199px) {
  .takfil-digital {
    background-position: left;
    margin-top: 100px;
  }
}
.takfil-digital::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.digital-container {
  background-color: var(--blur-color-white);
  backdrop-filter: blur(14px);
  color: var(--white-color);
  padding: 20px;
  border-radius: var(--border-radius);
  width: 100%;
  height: 100%;
  margin: 20px auto;
}
.digital-box img {
  max-width: 100%;
  height: 200px;
}
.digital-box-des {
  display: flex;
  flex-wrap: wrap;
}
.digital-box-des h2,
.digital-box h2 {
  width: 80% !important;
  font-weight: bold;
}
.digital-box-des {
  display: flex;
  flex-wrap: wrap;
}

.btnMore{
  width: max-content;
  background-color: #dd6d0e;
  color: white;
  height: 35px;
  border-radius: 7px;
  padding: 20px;
  padding-top: 3px;
  font-size: 1rem;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;

}
.digital-box-des p {
  display: flex;
  flex-direction: column;
 

}
.digital-box-des h2,
.digital-box h2,
.digital-box-des p,
.digital-box p {
  background-color: #1b84ab;
  font-size: 1rem;
  margin: 10px auto;
  padding: 10px;
  border-radius: var(--border-radius);
  width: fit-content;
  line-height: var(--line-height);
  width: 32%;
}
@media (max-width: 1199px) {
  .digital-box-des h2,
  .digital-box h2,
  .digital-box-des p,
  .digital-box p {
    width: 100%;
    margin: 30px auto;
    font-size: 0.8rem;
  }
}
.digital-box-des p img {
  position: relative;
  max-width: 100%;
  border-radius: var(--border-radius);
  margin: 0px auto 20px;
  height: 200px;
  animation: upDown 3s infinite linear ;
}
.digital-box-des p {
  margin: 20px auto;
  font-weight: normal;
}


@keyframes upDown {
  0% {
    top:15px;
  }
  50%{
    top: 0
  }
  100%{
    top: 15px;
  }
}


