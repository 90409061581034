.portfolio {
  position: relative;
  margin-top: 140px;
  padding: 20px 0px 50px;
  background-size: cover;
  background-attachment: fixed;
}
.portfolio h2 {
  position: relative;
  margin: 20px auto 50px;
  width: fit-content;
}
.portfolio h2::before {
  content: "";
  position: absolute;
  bottom: -7.5px;
  width: 50px;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  background-color: var(--white-color);
}
@media(max-width:1199px){
  .portfolio {
    margin-top: 100px;
    padding-top: 0px;
  }
}

